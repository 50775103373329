import React from "react";
import {LogoMitAblageort} from "../model/routing";
import {safeJoin} from "../shared/http";

import "./Logos.scss"

type LogosProps = {
    /**
     * Logos welche dargestellt werden sollen.
     * Dies können z.B. Vereinslogos oder Logos von Sponsoren sein.
     */
    logos: LogoMitAblageort[]
}

/**
 * Komponente welche alle Logos der Veranstaltung horizontal darstellt.
 */
function Logos(props: LogosProps) {

    const images = props.logos.map(l => {
        const src = safeJoin(l.vereinsordner, l.dateiname);

        return <img key={src}
                    src={src} alt="Logo"
                    width={l.breiteInPixel} height={l.hoeheInPixel}/>
    });

    return <div className="logos-container">{images}</div>;
}

export default Logos;
